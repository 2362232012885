<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div
            class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
        >
          <h3>System Settings</h3>
          <div class="justify-content-between">
              &nbsp;&nbsp;
              <router-link
                  :to="{ name: 'admin_create_setting' }"
                  v-if="auth.isAuthenticated"
                  class="ps-btn btn-sm"
              ><i class="icon-plus"></i> Create</router-link
              >
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor table-bordered">
            <thead>
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Value</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="setting in settings" :key="setting.id">
                <td>{{ setting.title }}</td>
                <td>{{ setting.code }}</td>
                <td>{{ setting.value }}</td>
                <td>
                  <i class="icon-check text-success" v-if="setting.is_active"></i>
                  <i class="icon-cross text-danger" v-if="!setting.is_active"></i>
                </td>
                <td>
                  <router-link
                      :to="{ name: 'admin_edit_setting', params: {id: setting.id}  }"
                      v-if="auth.isAuthenticated"
                  ><i class="icon-pencil"></i> </router-link>
                  &nbsp;&nbsp;
                </td>
              </tr>
            </tbody>
            <tfoot>
            <tr v-if="(settings.length === 0) && !isLoading">
              <td colspan="7" class="text-center m-4 text-danger p-4">
                No settings available
              </td>
            </tr>
            <tr v-else-if="isLoading">
              <td colspan="7" class="text-center m-4 text-danger">
                Loading settings...
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";
export default {
  data() {
    return {
      settings: [],
      meta: null,
      links: {},
      total: 0,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.isLoading = true;
      axios.get("settings").then((response) => {
          this.settings = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    scrollTop() {
      let element = document.getElementsByClassName("table-responsive");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },

    goToNextPage: function() {
      this.url = this.nextPage;
      if (this.url) {
        this.getSettings();
      }
      this.scrollTop();
    },

    goToPrevPage: function() {
      this.url = this.prevPage;
      if (this.url) {
        this.getSettings();
      }
      this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false;
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    }
  },
};
</script>
